export const edit = require("../assets/edit.png");
export const All = require("../assets/All.svg");
export const group = require("../assets/group.svg");
export const specific = require("../assets/specific.svg");
export const lightVerify = require("../assets/verificationModal.svg");
export const darkVerify = require("../assets/verification_dark.svg");
export const allBlack = require("../assets/allBlack.svg");
export const groupBlack = require("../assets/groupBlack.svg");
export const allManagerWhite = require("../assets/allManagerWhite.svg");
export const allManagersBlack = require("../assets/allManagersBlack.svg");
export const SpecificBlack = require("../assets/SpecificBlack.svg");