import React from "react";

// Customizable Area Start
import {
  Container,
  Box,
  Button,
  Input,
  Typography,
  InputAdornment,
  IconButton,
  Checkbox,
  styled,
  Link,
  Switch
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import { lightTheme, darkTheme, NextFormButton, TermsChecked } from "../../email-account-registration/src/EmailAccountRegistration.web";
import { logoLight, logoDark, productCard } from "../../email-account-registration/src/assets";
import { mailIcon, lockIcon } from "./assets"
const LoginContainer = styled(Box)(({ theme }) => ({
  height: '100vh',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  background: theme.palette.background.default,
  '& .MuiPaper-root': {
    backgroundColor: theme.palette.primary.main
  }
}))

const HeaderLogin = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  width: '1000px'
})

const HomeLink = styled(Link)(({ theme }) => ({
  fontFamily: 'Rubik',
  fontSize: '24px',
  fontWeight: 700,
  lineHeight: '32px',
  letterSpacing: '-0.005em',
  color: theme.palette.info.contrastText
}))

const LoginBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  width: '900px',
})
const UpperImg = styled('img')({
  width: '276.49px',
  height: '303.72px',
  gap: '0px',
  opacity: '0.5',
  position: 'fixed',
  top: -20,
  transform: 'rotate(-10deg)',
  alignSelf: 'flex-end'
})

const LowerImg = styled('img')({
  width: '276.49px',
  height: '303.72px',
  gap: '0px',
  opacity: '0.5',
  transform: 'rotate(-180deg)',
  alignSelf: 'flex-start',
  position: 'fixed',
  bottom: 60
})

const MainCard = styled(Box)(({ theme }) => ({
  boxShadow: '0px 2px 8px 0px #00000014',
  width: '696px',
  padding: '28px',
  gap: '28px',
  borderRadius: '30px',
  background: theme.palette.primary.main,
  zIndex: 1,
  '& .MuiInput-underline::before': {
    borderBottom: 'transparent'
  },
  '& .MuiInput-underline::after': {
    borderBottom: 'transparent'
  },
  '& .MuiInput-underline:hover:not(.Mui-disabled)::before': {
    borderBottom: 'transparent'
  },
  '& .MuiButton-contained:hover': {
    backgroundColor: theme.palette.action.active
  },
  '& .MuiButton-root:hover': {
    backgroundColor: theme.palette.action.active
  }
}))

const InputField = styled(Input)(({ theme }) => ({
  width: '100%',
  gap: '8px',
  borderRadius: '8px',
  padding: '16px',
  border: `1px solid ${theme.palette.info.dark}`,
  fontFamily: 'Rubik',
  fontSize: '16px',
  fontWeight: 500,
  lineHeight: '24px',
  textAlign: 'left',
  color: theme.palette.secondary.main,
  marginTop: '5px',
  background: theme.palette.background.default,
  '& .MuiInput-underline:hover:not(.Mui-disabled)::before': {
    borderBottom: 'transparent'
  },
  '& .MuiInput-underline::after': {
    borderBottom: 'transparent'
  },
  '& .MuiSvgIcon-root': {
    fill: '#94A3B8'
  }
}))

export const SwitchTheme = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  marginTop: '20px',
  "& p": {
    marginTop: '2px',
    marginLeft: '5px',
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '24px',
    color: theme.palette.text.primary
  },
  '& .MuiSwitch-root': {
    width: '48px',
    height: '28px',
    padding: 0
  },
  '& .MuiIconButton-root': {
    padding: '2px !important'
  },
  "& .MuiSwitch-track": {
    borderRadius: '40px',
    opacity: 1,
    background: '#E2E8F0'
  },
  "& .MuiSwitch-thumb": {
    width: 24,
    height: 24
  },
  '& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track': {
    backgroundColor: "1A469C"
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    opacity: 1
  }
}))

const RememberText = styled('span')(({ theme }) => ({
  fontFamily: 'Rubik',
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '24px',
  textAlign: 'left',
  color: theme.palette.secondary.main,
  marginLeft: '10px'
}))

const ForgotPasswordLink = styled(Typography)(({ theme }) => ({
  fontSize: "16px",
  lineHeight: '24px',
  fontFamily: 'Rubik',
  color: theme.palette.info.contrastText,
  fontWeight: 500,
  marginTop: "10px",
  textDecoration: 'underline',
  cursor: 'pointer'
}))
import { ToastContainer } from "react-toastify";
// Customizable Area End

import EmailAccountLoginController, {
  Props,
} from "./EmailAccountLoginController";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class EmailAccountLoginBlock extends EmailAccountLoginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
}
 // Customizable Area Start
 // Customizable Area End
  render() {
    return (
       // Customizable Area Start
      // Required for all blocks
      <ThemeProvider theme={this.state.enableDarkMode ? darkTheme : lightTheme}>
        <LoginContainer>
          <HeaderLogin>
            <img src={this.state.enableDarkMode ? logoLight : logoDark} alt="logo" style={{ width: "240px" }} />
            <HomeLink href="/">HOME</HomeLink>
          </HeaderLogin>
          <LoginBox>
            <UpperImg src={productCard} />
            <MainCard>
              <Typography
                color="secondary"
                variant="h4"
                component="h2"
                style={{ textAlign: 'center', fontFamily: 'Poppins', fontWeight: 700, fontSize: '24px', lineHeight: '32px' }}
              >
                {this.labelTitle}
              </Typography>
              <Box sx={{ width: "100%", paddingTop: "20px" }}>
                <Box sx={{ padding: "10px 0px" }}>
                  <Typography
                    color="secondary"
                    variant="subtitle1"
                    component="div"
                    style={{ fontFamily: 'Rubik', fontWeight: 400, fontSize: '16px', lineHeight: '24px' }}
                  >
                    Email address
                  </Typography>
                  <InputField
                    data-test-id="txtInputEmail"
                    placeholder="Enter your email address"
                    fullWidth
                    value={this.state.email}
                    onChange={(event) => this.setEmail(event.target.value)}
                    onKeyPress={this.checkLogin}
                    startAdornment={
                      <InputAdornment position="start">
                        <img src={mailIcon} />
                      </InputAdornment>
                    }
                  />
                </Box>
                <Box sx={{ padding: "10px 0px" }}>
                  <Typography color="secondary" variant="subtitle1" component="div" style={{ fontFamily: 'Rubik', fontWeight: 400, fontSize: '16px', lineHeight: '24px' }}>
                    Password
                  </Typography>
                  <InputField
                    data-test-id="txtInputPassword"
                    type={this.state.enablePasswordField ? "password" : "text"}
                    placeholder="Enter your password"
                    fullWidth
                    value={this.state.password}
                    onChange={(event) => this.setPassword(event.target.value)}
                    onKeyPress={this.checkLogin}
                    startAdornment={
                      <InputAdornment position="start">
                        <img src={lockIcon} />
                      </InputAdornment>
                    }
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={this.handleClickShowPassword}
                          edge="end"
                        >
                          {this.state.enablePasswordField ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Box
                    sx={{
                      fontSize: "16px",
                      lineHeight: '24px',
                      fontFamily: 'Rubik',
                      fontWeight: 500,
                      marginTop: "10px",
                    }}
                  >
                    <TermsChecked
                      data-test-id={"btnRememberMe"}
                      onClick={() =>
                        this.setRememberMe(!this.state.checkedRememberMe)
                      }
                      checked={this.state.checkedRememberMe}
                    />{" "}
                    <RememberText color="secondary">Remember me</RememberText>
                  </Box>
                  <ForgotPasswordLink
                    data-test-id={"btnForgotPassword"}
                    onClick={() => this.goToForgotPassword()}
                  >
                    Forget your password
                  </ForgotPasswordLink>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    padding: "10px 0px",
                  }}
                >
                  <NextFormButton
                    style={{ width: '100%', textTransform: 'none' }}
                    data-test-id={"btnEmailLogIn"}
                    variant="contained"
                    fullWidth
                    onClick={() => this.doEmailLogIn()}
                  >
                    Log in {/*UI Engine::From Sketch*/}
                  </NextFormButton>
                </Box>
              </Box>
            </MainCard>
            <LowerImg src={productCard} />
          </LoginBox>
          <SwitchTheme>
            <Switch data-test-id="toggleTheme" checked={this.state.enableDarkMode} onChange={this.changeTheme} />
            <Typography>Dark Theme</Typography>
          </SwitchTheme>
        </LoginContainer>
        <ToastContainer />
      </ThemeProvider>
       // Customizable Area End
    );
  }
}
