// Customizable Area Start
import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  Grid,
  styled,
  Menu,
  MenuItem,
  FormControlLabel,
  Avatar,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { ThemeProvider } from "@material-ui/core/styles";
import { BackButton, NextButton, darkTheme, lightTheme } from "../../email-account-registration/src/EmailAccountRegistration.web";
import Loader from "../../../components/src/Loader.web";
import HamburgerMenu from "../../hamburgermenu/src/HamburgerMenu.web";
import AddBoxOutlinedIcon from '@material-ui/icons/AddBoxOutlined';
import { MainContainer, HeadDiv, DotSection, NameField, DesignationField } from "../../notifications/src/Notifications.web";
import { deleteIcon, alarmImage } from "../../customisableuserprofiles/src/assets";
import { FieldModal, ModalHeading, ModalLine, ModalSubHead } from "../../customisableuserprofiles/src/CustomisableUserProfiles.web";
import { Pagination } from "@material-ui/lab";
import MoreVertOutlined from "@material-ui/icons/MoreVertOutlined";
import { ToastContainer } from "react-toastify";
import SearchIcon from '@material-ui/icons/Search';
import { All, SpecificBlack, allBlack, allManagerWhite, allManagersBlack, darkVerify, lightVerify, specific } from "../../postcreation/src/assets";
import { Radio, Checkbox, Select } from "antd";
import { FileNameTxt, FileSizeTxt, MenuLines, RadioDiv, RadioOptionHead, RadioOptionSubHead } from "../../postcreation/src/PostCreation.web";
import CloseIcon from '@material-ui/icons/Close';
import ReactQuill from "react-quill";
import { ArrowBackIosOutlined } from "@material-ui/icons";
import { CameraBlack, CameraWhite, attachPin, attachPinBlack, deleteBlack, deleteWhite, downloadIcon, edit_Black, edit_white, groupBlack, groupWhite, searchWhite, visibilityBlack, visibilityWhite } from "./assets";
import moment from "moment";
import { DateText, CountChar } from "../../tasks/src/TaskList.web";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
interface DocObj {
  url?: string,
  id?: number
}
// Customizable Area End

import BulletinBoardController, {
  Props,
  configJSON,
} from "./BulletinBoardController";

export default class BulletinBoard extends BulletinBoardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  BulletinModal = () => {
    return <FieldModal
      data-test-id='addBulletinModal'
      className="scrollDiv"
      open={this.state.bulletinModal}
      onCancel={this.closeBulletinModal}
      title={
        <div style={{ display: 'flex', justifyContent: "space-between", flexDirection: 'row', alignItems: "center" }}>
          <ModalHeading variant="h1" style={{ textAlign: 'left' }}>{this.state.editBulletinKey ? "Edit" : 'Add'} Bulletin</ModalHeading>
          <ModalLine style={{ width: "65%" }} />
        </ div>}
      centered
      closable={true}
      footer={null}
      destroyOnClose
      width={736}
      style={{ height: '525px', overflowY: 'scroll' }}
    >
      <div style={{ padding: '10px 30px' }}>
        <SubTitle>Bulletin title</SubTitle>
        <TitleField
          data-test-id="txtBulletinTitle"
          value={this.state.title}
          onChange={this.changeTitle}
        />
        <SubTitle>Bulletin Description</SubTitle>
        <ReactQuill
          data-test-id="addDescription"
          placeholder={"Add your bulletin description"}
          value={this.state.description}
          onChange={(event) => this.setState({ description: event })}
          formats={["bold", "italic", "underline", "strike", "blockquote", "list", "bullet", "indent", "link", "image", "color"]}
          onKeyPress={this.countChar}
        />
        <SubTitle>Add File</SubTitle>
        <FileSection>
          <div style={{ width: "96%", marginRight: '25px' }}>
            {this.state.document?.map((item, index) => <DocViewDiv >
              <div style={{ display: 'flex' }}>
                <MenuLines />
                <div style={{ marginLeft: "15px" }}>
                  <FileNameTxt style={{ fontSize: '10px' }}>{item.name}</FileNameTxt>
                  <FileSizeTxt style={{ fontSize: '9px' }}>{(item.size / 1024).toFixed(0)}{' KB'}</FileSizeTxt>
                </div>
              </div>
              <CloseIcon
                data-test-id="removeFile"
                style={{ width: '10px', marginRight: "20px" }}
                onClick={() => this.removeDocument(index)}
              />
            </DocViewDiv>)}
          </div>
          <div style={{ width: "4%" }}>
            <label htmlFor="uploadDocument">
              <input
                data-test-id="uploadDocument"
                id="uploadDocument"
                type="file"
                style={{ display: "none" }}
                accept=".pdf,.doc,.docx,.txt,.xls,.xlsx"
                onChange={this.uploadDocument}
              />
              <img src={this.state.themeStatus ? attachPinBlack : attachPin} style={{ marginTop: `${this.state.document.length > 0 ? "15px" : "0px"}`, cursor: "pointer" }} />
            </label>
          </div>
        </FileSection>
        <SubTitle>Add Link</SubTitle>
        <LinkField
          data-test-id="txtBulletinLink"
          value={this.state.link}
          onChange={this.changeLink}
        />
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
          <img src={this.state.themeStatus ? CameraBlack : CameraWhite} /><SubTitle>{" Add photo/video"}</SubTitle>
        </div>
        <label htmlFor="uploadImage" className="profile-upload-btn" data-test-id="profile-upload-btn">
          <input
            data-test-id="uploadImage"
            id="uploadImage"
            type="file"
            accept="image/*,video/*"
            style={{ display: "none" }}
            onChange={this.uploadImage}
          />
          <CameraDiv>
            <img src={this.state.themeStatus ? CameraBlack : CameraWhite} />
            <SubTitle>Add</SubTitle>
          </CameraDiv>
        </label>
        <AttachmentDiv>
          {this.state.uploadedImages?.map((file: any, index) =>
            <div>
              {this.state.editBulletinKey ? <PreviewImage src={file.url} /> : <PreviewImage src={URL.createObjectURL(file)} />}
              <CloseDiv
                data-test-id="removeImage"
                onClick={() => this.removeImage(index)}
              />
            </div>)}
          {this.state.uploadedVideos?.map((file: any, index) =>
            <div>
              {this.state.editBulletinKey ? <PreviewVideo src={file.url} /> : <PreviewVideo src={URL.createObjectURL(file)} />}
              <CloseDiv
                data-test-id="removeVideo"
                onClick={() => this.removeVideo(index)}
              />
            </div>)}
        </AttachmentDiv>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
          <SaveButton
            data-test-id="addBulletinButton"
            onClick={this.openPublishTypeModal}
          >
            Publish
          </SaveButton>
        </div>
      </div>
      <ToastContainer containerId="A" />
    </FieldModal>
  }

  publishTypeModal = () => (
    <FieldModal
      open={this.state.publishTypeModal}
      onCancel={this.closePublishTypeModal}
      title={
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <ModalHeading variant="h5">select where to publish</ModalHeading>
          <ModalLine style={{ width: '100px' }} />
          <ModalSubHead variant="subtitle2">publish Info</ModalSubHead>
          <ModalLine style={{ width: '100px' }} />
        </div >}
      centered
      footer={null}
      destroyOnClose
      closable={true}
      width={769}
      style={{ paddingBottom: '40px' }}
    >
      <Radio.Group
        data-test-id='changePublishType'
        value={this.state.publishTypeKey}
        onChange={this.changePublishType}
        style={{ width: "100%" }}
      >
        <div style={{ display: 'flex', alignItems: "center" }}>
          <ArrowBackIosOutlined style={{ marginRight: "200px", marginLeft: '20px', fontSize: '15px' }} onClick={this.closePublishTypeModal} />
          <ModalHeading variant="h5">where to publish</ModalHeading>
        </div>
        <div style={{ maxHeight: '360px', overflowY: "scroll", overflowX: "hidden", padding: "10px" }} className="scrollDiv">
          <FormControlLabel
            control={<Radio />}
            value='all_companies'
            labelPlacement="start"
            label={<RadioDiv>
              <img src={this.state.themeStatus ? allBlack : All} />
              <div>
                <RadioOptionHead>All</RadioOptionHead>
                <RadioOptionSubHead>The reminder will be sent to all companies “Workers and Managers”.</RadioOptionSubHead>
              </div>
            </RadioDiv>}
          />
          <FormControlLabel
            control={<Radio />}
            value='specific_companies'
            labelPlacement="start"
            label={<RadioDiv>
              <img src={this.state.themeStatus ? SpecificBlack : specific} />
              <div>
                <RadioOptionHead>Specific Companies</RadioOptionHead>
                <RadioOptionSubHead>The reminder will be sent to specific companies “Workers and Managers”.</RadioOptionSubHead>
                <RadioOptionSubHead>{this.state.companyNames.slice(0, 2)?.map((item) => `${item.attributes.company_name},`)}and {this.state.companyNames.slice(2)?.length} others</RadioOptionSubHead>
              </div>
            </RadioDiv>}
          />
          <FormControlLabel
            control={<Radio />}
            value='all_groups'
            labelPlacement="start"
            label={<RadioDiv>
              <img src={this.state.themeStatus ? groupBlack : groupWhite} />
              <div>
                <RadioOptionHead>All Groups</RadioOptionHead>
                <RadioOptionSubHead>The reminder will be sent to all groups of all companies.</RadioOptionSubHead>
              </div>
            </RadioDiv>}
          />
          <FormControlLabel
            control={<Radio />}
            value='specific_groups'
            labelPlacement="start"
            label={<RadioDiv>
              <img src={this.state.themeStatus ? groupBlack : groupWhite} />
              <div>
                <RadioOptionHead>Specific Groups</RadioOptionHead>
                <RadioOptionSubHead>The bulletin will be sent to specific groups.</RadioOptionSubHead>
                <RadioOptionSubHead>{this.state.groupNames.slice(0, 2)?.map((item) => `${item.attributes.group_name},`)}and {this.state.groupNames.slice(2)?.length} others</RadioOptionSubHead>
              </div>
            </RadioDiv>}
          />
          <FormControlLabel
            control={<Radio />}
            value='all_managers'
            labelPlacement="start"
            label={<RadioDiv>
              <img src={this.state.themeStatus ? allManagersBlack : allManagerWhite} />
              <div>
                <RadioOptionHead>All Managers</RadioOptionHead>
                <RadioOptionSubHead>The reminder will be sent to all Managers of all companies.</RadioOptionSubHead>
              </div>
            </RadioDiv>}
          />
          <FormControlLabel
            control={<Radio />}
            value='specific_managers'
            labelPlacement="start"
            label={<RadioDiv>
              <img src={this.state.themeStatus ? allManagersBlack : allManagerWhite} />
              <div>
                <RadioOptionHead>Specific Managers</RadioOptionHead>
                <RadioOptionSubHead>The reminder will be sent to specific managers.</RadioOptionSubHead>
                <RadioOptionSubHead>{this.state.managerNames.slice(0, 2)?.map((item) => `${item.attributes.preferred_name},`)}and {this.state.managerNames.slice(2)?.length} others</RadioOptionSubHead>
              </div>
            </RadioDiv>}
          />
        </div>
      </Radio.Group>
      <BackButton data-test-id="closeModalButton" onClick={() => { this.setState({ publishTypeModal: false, publishTypeKey: "" }) }}>Back</BackButton>
      <NextButton
        data-test-id="addPublishTypeButton"
        style={{ width: '68%', marginLeft: '10px' }}
        onClick={() => this.callOperation()}
        disabled={!this.state.publishTypeKey}
      >
        {(this.state.publishTypeKey === "all_groups" || this.state.publishTypeKey === "all_companies" || this.state.publishTypeKey === "all_managers") ? "Publish" : "Next"}
      </NextButton>
    </FieldModal >
  )

  callOperation = () => {
    if (this.state.editBulletinKey && (this.state.publishTypeKey === "all_groups" || this.state.publishTypeKey === "all" || this.state.publishTypeKey === "all_managers")) {
      this.editBulletin()
    } else {
      if (this.state.publishTypeKey === "all_groups" || this.state.publishTypeKey === "all" || this.state.publishTypeKey === "all_managers") {
        this.addNewBulletin()
      } else {
        this.openSpecificGroupModal()
      }
    }
  }

  specificGroupModal = () => (
    <FieldModal
      open={this.state.specificGroupModal}
      onCancel={this.closeSpecificGroupModal}
      title={
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <ModalHeading variant="h5">select where to publish</ModalHeading>
          <ModalLine style={{ width: '50px' }} />
          <ModalSubHead variant="subtitle2">publish Info</ModalSubHead>
          <ModalLine style={{ width: '50px' }} />
        </div >}
      centered
      footer={null}
      destroyOnClose
      closable={true}
      width={650}
      style={{ paddingBottom: '40px' }}
    >
      <div style={{ display: 'flex', alignItems: "center" }}>
        <ArrowBackIosOutlined style={{ marginRight: "150px", marginLeft: '20px', fontSize: '15px' }} onClick={this.closeSpecificGroupModal} />
        <ModalHeading variant="h5">where to publish</ModalHeading>
      </div>
      <SearchInput
        data-test-id="searchGroupKey"
        startAdornment={<img src={searchWhite} style={{ marginLeft: "10px" }} />}
        placeholder="Search"
        onChange={this.changeSearchKey}
        onKeyPress={this.searchGroup}
      />
      <div className="scrollDiv" style={{ maxHeight: "210", overflowY: 'scroll', paddingRight: "10px" }}>
        {this.state.publishTypeKey === "specific_groups" && this.state.groupNames?.map((item) => (
          <div style={{ display: "flex", justifyContent: "space-between", marginLeft: "15px", marginBottom: "10px", alignItems: "center" }}>
            <RadioDiv>
              <img src={this.state.themeStatus ? groupBlack : groupWhite} style={{ marginRight: "15px", width: "32px", height: "32px" }} />
              <div>
                <CompanyName>Group Name</CompanyName>
                <CompanyNameSubHead>{item.attributes.group_name}</CompanyNameSubHead>
              </div>
            </RadioDiv>
            <CompanyChecked
              data-test-id="selectGroupId"
              checked={this.state.selectedGroupIds.includes(Number(item.attributes.id))}
              onChange={(event) => this.selectGroup(event, item.attributes.id)}
            />
          </div>
        ))}
        {this.state.publishTypeKey === "specific_companies" && this.state.companyNames?.map((item) => (
          <div style={{ display: "flex", justifyContent: "space-between", marginLeft: "15px", marginBottom: "10px", alignItems: "center" }}>
            <RadioDiv>
              <img src={this.state.themeStatus ? groupBlack : groupWhite} style={{ marginRight: "15px", width: "32px", height: "32px" }} />
              <div>
                <CompanyName>company Name</CompanyName>
                <CompanyNameSubHead>{item.attributes.company_name}</CompanyNameSubHead>
              </div>
            </RadioDiv>
            <CompanyChecked
              data-test-id="selectCompanyId"
              checked={this.state.selectedCompaniesIds.includes(Number(item.attributes.id))}
              onChange={(event) => this.selectCompany(event, item.attributes.id)}
            />
          </div>
        ))}
        {this.state.publishTypeKey === "specific_managers" && this.state.managerNames?.map((item) => (
          <div style={{ display: "flex", justifyContent: "space-between", marginLeft: "15px", marginBottom: "10px", alignItems: "center" }}>
            <RadioDiv>
              <img src={this.state.themeStatus ? groupBlack : groupWhite} style={{ marginRight: "15px", width: "32px", height: "32px" }} />
              <div>
                <CompanyName>Manager Name</CompanyName>
                <CompanyNameSubHead>{item.attributes.preferred_name}</CompanyNameSubHead>
              </div>
            </RadioDiv>
            <CompanyChecked
              data-test-id="selectManagerId"
              checked={this.state.selectedManagersIds.includes(Number(item.attributes.id))}
              onChange={(event) => this.selectManager(event, item.attributes.id)}
            />
          </div>
        ))}
      </div>
      {this.state.selectedGroupIds.length > 0 && <SelectedGroupDiv>
        {this.state.groupNames?.map((item) =>
        (
          this.state.selectedGroupIds.includes(Number(item.attributes.id)) &&
          <SelectedGroupBox>
            <RadioDiv>
              <Avatar style={{ marginRight: "10px", width: "21px", height: "23px" }} />
              <div>
                <CompanyName style={{ fontSize: "6px" }}>Group Name</CompanyName>
                <CompanyNameSubHead style={{ fontSize: "10px", lineHeight: "8px" }}>
                  {item.attributes.group_name}
                </CompanyNameSubHead>
              </div>
            </RadioDiv>
            <CloseIcon
              data-test-id="removeGroup"
              style={{ width: "7px", height: "7px" }}
              onClick={() => this.removeGroup(item.attributes.id)} />
          </SelectedGroupBox>

        ))}
      </SelectedGroupDiv>}
      {this.state.selectedCompaniesIds.length > 0 && <SelectedGroupDiv>
        {this.state.companyNames?.map((item) =>
        (
          this.state.selectedCompaniesIds.includes(Number(item.attributes.id)) &&
          <SelectedGroupBox>
            <RadioDiv>
              <Avatar style={{ marginRight: "10px", width: "21px", height: "23px" }} />
              <div>
                <CompanyName style={{ fontSize: "6px" }}>Company Name</CompanyName>
                <CompanyNameSubHead style={{ fontSize: "10px", lineHeight: "8px" }}>
                  {item.attributes.company_name}
                </CompanyNameSubHead>
              </div>
            </RadioDiv>
            <CloseIcon
              data-test-id="removeCompany"
              style={{ width: "7px", height: "7px" }}
              onClick={() => this.removeCompany(item.attributes.id)} />
          </SelectedGroupBox>

        ))}
      </SelectedGroupDiv>}
      {this.state.selectedManagersIds.length > 0 && <SelectedGroupDiv>
        {this.state.managerNames?.map((item) =>
        (
          this.state.selectedManagersIds.includes(Number(item.attributes.id)) &&
          <SelectedGroupBox>
            <RadioDiv>
              <Avatar style={{ marginRight: "10px", width: "21px", height: "23px" }} />
              <div>
                <CompanyName style={{ fontSize: "6px" }}>Manager Name</CompanyName>
                <CompanyNameSubHead style={{ fontSize: "10px", lineHeight: "8px" }}>
                  {item.attributes.preferred_name}
                </CompanyNameSubHead>
              </div>
            </RadioDiv>
            <CloseIcon
              data-test-id="removeManager"
              style={{ width: "7px", height: "7px" }}
              onClick={() => this.removeManager(item.attributes.id)} />
          </SelectedGroupBox>

        ))}
      </SelectedGroupDiv>}
      <BackButton data-test-id="closeSpecificModal" onClick={this.backSpecificModal} style={{ marginLeft: "8px" }}>
        Back
      </BackButton>
      <NextButton
        data-test-id="specificGroupAddBtn"
        style={{ width: '65%', marginLeft: '10px' }}
        onClick={this.state.editBulletinKey ? this.editBulletinApi : this.addNewBulletin}
        disabled={this.state.selectedGroupIds.length == 0 && this.state.selectedCompaniesIds.length == 0 && this.state.selectedManagersIds.length == 0}
      >
        Publish
      </NextButton>
    </FieldModal >
  )

  apiSuccessModal = () => (
    <FieldModal
      data-test-id="successModal"
      open={this.state.apiSuccessModal}
      onCancel={this.closePublishTypeModal}
      closable={true}
      centered
      destroyOnClose
      footer={null}
      style={{ height: '430px' }}
      width={585}
    >
      <div style={{ display: 'flex', justifyContent: "center", padding: '20px', flexDirection: 'column', alignItems: 'center' }}>
        <img src={this.state.themeStatus ? darkVerify : lightVerify} alt="verificationModal" style={{ height: '176px' }} />
        <SuccessMessage style={{ textAlign: 'center' }}>Your bulletin has been {this.state.successMessage} Successfully</SuccessMessage>
      </div>
      <NextButton
        data-test-id="confirmModalButton"
        fullWidth
        onClick={this.closePublishTypeModal}
        style={{ color: '#F8FAFC' }}
      >
        Done
      </NextButton>
    </FieldModal>
  )

  deleteModal = () => (
    <FieldModal
      onCancel={this.closeBulletinModal}
      open={this.state.deleteModal}
      title={<div style={{ textAlign: "left" }}>
        <ModalHeading variant="h1">Delete</ModalHeading>
      </div>}
      centered
      footer={null}
      closable={false}
      width={611}
      destroyOnClose
      style={{ height: '356px', padding: '40px' }}
    >
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <img src={this.state.themeStatus ? deleteBlack : deleteWhite} style={{ width: "111px", height: "111px", marginRight: '10px' }} />
        <ModalSubHead variant="body1">
          Deleting this post will remove your content permanently. Would you like to continue?
        </ModalSubHead>
      </div>
      <BackButton data-test-id="closeDeleteModal" onClick={this.closeBulletinModal}>Cancel</BackButton>
      <NextButton data-test-id='deleteBulletin' onClick={this.deleteBulletinApi} style={{ width: '65%', color: '#F8FAFC', marginLeft: '10px' }}>Yes</NextButton>
    </FieldModal>
  )

  renderCard = (item: any) => (
    <BulletinCard key={item.id}>
      <CardTitle>{item.attributes.name}</CardTitle>
      <div style={{ display: 'flex', alignItems: 'baseline', justifyContent: 'space-between' }}>
        <DescriptionDiv dangerouslySetInnerHTML={{ __html: item.attributes.description }} />
        {this.state.viewBulletinKey ?
          <DotSection>
            <CloseIcon
              data-test-id="closeView"
              onClick={() => this.setState({ viewBulletinKey: false })}
            />
          </DotSection>
          :
          <DotSection>
            <IconButton
              aria-controls="menu-appbar"
              aria-label="account of current user"
              aria-haspopup="true"
              onClick={(event) => this.OpenNavMenu(event, item.attributes.id, item.attributes.profile.data.attributes.id)}
              data-test-id="openOption"
              color="inherit"
            >
              <MoreVertOutlined />
            </IconButton>
            <MainMenu
              anchorEl={this.state.anchorElNav}
              id="menu-appbar"
              anchorOrigin={{
                horizontal: 'right',
                vertical: 'bottom',
              }}
              keepMounted
              transformOrigin={{
                horizontal: 'right',
                vertical: 'top',
              }}
              onClose={this.closeNavMenu}
              open={Boolean(this.state.anchorElNav)}
              style={{ top: 30 }}
              elevation={0}
            >
              <MenuItem data-test-id='viewBulletin'
                onClick={this.viewBulletin}
              >
                <img height={18} width={18} src={this.state.themeStatus ? visibilityBlack : visibilityWhite} style={{ marginRight: 10 }} />
                <ViewOption>View</ViewOption>
              </MenuItem>
              {(this.state.userProfile.attributes.id == this.state.profileId) &&
                <>
                  <MenuItem data-test-id='editPost'
                    onClick={this.editBulletin}
                  >
                    <img height={18} width={18} src={this.state.themeStatus ? edit_Black : edit_white} style={{ marginRight: 10 }} />
                    <EditOption>Edit</EditOption>
                  </MenuItem>

                  <MenuItem data-test-id='deletePost'
                    onClick={this.deleteBulletin}
                  >
                    <img height={18} width={18} src={deleteIcon} style={{ marginRight: 10 }} />
                    <Typography style={{ fontSize: 14, color: '#DC2626' }}>Delete</Typography>
                  </MenuItem>
                </>}
            </MainMenu>
          </DotSection>}
      </div>
      <ImageVideoSection>
        {(this.state.viewBulletinKey ? item.attributes.images : item.attributes.images?.slice(0, 1))?.map((image: DocObj) => (
          <PreviewImage src={image.url} />
        ))}
        {(this.state.viewBulletinKey ? item.attributes.videos : item.attributes.videos?.slice(0, 1))?.map((video: DocObj) => (
          <PreviewVideo controls>
            <source src={video.url} />
          </PreviewVideo>
        ))}
        {(this.state.viewBulletinKey ? item.attributes.documents : item.attributes.documents?.slice(0, 1))?.map((doc: DocObj) => (
          <DocViewDiv>
            <div style={{ display: 'flex' }}>
              <MenuLines />
              <div style={{ marginLeft: "15px" }}>
                <FileNameTxt style={{ fontSize: '10px' }}>Filename.doc</FileNameTxt>
                <FileSizeTxt style={{ fontSize: '9px' }}>128{' KB'}</FileSizeTxt>
              </div>
            </div>
            <a href={doc.url} download target="_blank" style={{ cursor: "pointer" }}>
              <img src={downloadIcon} style={{ height: "20px", width: "20px" }} />
            </a>
          </DocViewDiv>
        ))}
      </ImageVideoSection>
      <LinkSection>
        <SubTitle>{"Link: "}</SubTitle>
        <a target="_blank" style={{ cursor: "pointer" }}>{item.attributes.link}</a>
      </LinkSection>
      <div style={{ display: "flex", flexDirection: 'row', justifyContent: "space-between", width: '100%', alignItems: "center" }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Avatar alt="Remy Sharp" src={item.attributes.profile.data.attributes.image} />
          <div style={{ marginLeft: '10px' }}>
            <NameField style={{ fontSize: '11.51px' }}>{item.attributes.profile.data.attributes.preferred_name}</NameField>
            <DesignationField style={{ fontSize: '12px' }}>{item.attributes.profile.data.attributes.role_id}</DesignationField>
          </div>
        </div>
        <DateText>
          {moment.utc(item.attributes.created_at).format('MM/DD/YYYY h:mm A')}
        </DateText>
      </div>
    </BulletinCard>
  )
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={this.state.themeStatus ? darkTheme : lightTheme}>
        <Loader loading={this.state.isLoading} />
        <HamburgerMenu
          navigation={this.props.navigation}
          handleMenu={this.toggleMenu}
          open={this.state.menuStatus}
          themeDark={this.state.themeStatus}
          handleTheme={this.toggleTheme}
        />
        <NavigationMenu navigation={undefined} id={""} theme={this.state.themeStatus} open={this.state.menuStatus} />
        <MainContainer style={{ paddingLeft: this.state.menuStatus ? 273 : 150 }}>
          <HeadDiv >
            <BodyHeader>
              <div>
                <BulletinTitle>Bulletin Board</BulletinTitle>
                <BulletinSubTitle>see what’s new today!</BulletinSubTitle>
              </div>
              {this.state.bulletinList.length > 0 &&
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Select
                    data-test-id="filterValue"
                    onChange={this.changeFilterValue}
                    placeholder='Date Range filter'
                  >
                    <Select.Option value={'student'}>Worker</Select.Option>
                    <Select.Option value={'educator'}>Instructor</Select.Option>
                    <Select.Option value={'manager/supervisor'}>Supervisor</Select.Option>
                  </Select>
                  <AddModuleButton
                    data-test-id="addBulletin"
                    startIcon={<AddBoxOutlinedIcon />}
                    onClick={this.openBulletinModal}
                  >
                    Add Bulletin Board
                  </AddModuleButton>
                </div>}
            </BodyHeader>
            {this.state.bulletinList.length > 0 ? <Grid container>
              <Grid item xs={12}>
                {this.state.viewBulletinKey ?
                  this.renderCard(this.state.selectedBulletin)
                  : this.state.bulletinList?.map((item) => {
                    return this.renderCard(item)
                  })}
              </Grid>

            </Grid> :
              <EmptyContainer container>
                <EmptyBoardHeading>Empty bulletin board</EmptyBoardHeading>
                <EmptyBoardSubHeading>Add first bulletin </EmptyBoardSubHeading>
                <AddModuleButton
                  data-test-id="addBulletin1"
                  startIcon={<AddBoxOutlinedIcon />}
                  onClick={this.openBulletinModal}
                >
                  Add Bulletin
                </AddModuleButton>
              </EmptyContainer>}
            {(this.state.bulletinList.length > 0 && !this.state.viewBulletinKey) && <Pagination
              count={this.state.totalPage}
              data-test-id="changePageNumber"
              onChange={this.changePageNumber}
              page={this.state.currentPage}
              size="small"
            />}
          </HeadDiv>
          {this.BulletinModal()}
          {this.publishTypeModal()}
          {this.specificGroupModal()}
          {this.apiSuccessModal()}
          {this.deleteModal()}
          <ToastContainer />
        </MainContainer>
      </ThemeProvider >
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const BulletinTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  lineHeight: '40px',
  fontSize: '30px',
  color: theme.palette.info.main
}))

const BulletinSubTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  lineHeight: '14px',
  fontSize: '14px',
  letterSpacing: '0.41px',
  color: theme.palette.info.main
}))

const BodyHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  margin: '10px',
  "& .ant-select-dropdown": {
    width: 'auto !important',
    fontFamily: 'Rubik',
    fontWeight: 400,
    fontSize: '12px',
    minWidth: "200px",
    background: theme.palette.action.focus,
    borderRadius: "18px"
  },
  "& .ant-select:not(.ant-select-customize-input) .ant-select-selector": {
    height: "36px",
    background: theme.palette.warning.main,
    border: `0.8px solid ${theme.palette.info.dark}`,
    borderRadius: "6.33px",
  },
  "& .ant-select": {
    marginRight: "10px"
  }
}))

const AddModuleButton = styled(Button)(({ theme }) => ({
  width: '100%',
  maxWidth: '208px',
  height: '44px',
  borderRadius: '30px',
  border: `1px solid ${theme.palette.info.contrastText}`,
  padding: '10px 16px',
  textTransform: 'capitalize',
  fontWeight: 500,
  fontSize: '16px',
  lineHeight: '24px',
  color: theme.palette.info.contrastText,
  '& .MuiSvgIcon-root': {
    fill: theme.palette.info.contrastText
  }
}))

const BulletinCard = styled(Box)(({ theme }) => ({
  background: theme.palette.background.default,
  padding: '12px 16px',
  borderRadius: '6px',
  margin: '5px',
  borderLeft: `4px solid ${theme.palette.info.contrastText}`
}))

const CardTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  lineHeight: '24px',
  fontSize: '16px',
  color: theme.palette.info.main
}))

const SaveButton = styled(Button)(({ theme }) => ({
  height: '44px',
  width: '100%',
  maxWidth: '92px',
  padding: '10px 16px',
  borderRadius: '30px',
  background: theme.palette.info.contrastText,
  color: '#F8FAFC',
  fontWeight: 700,
  fontSize: '16px',
  lineHeight: '24px',
  textTransform: 'capitalize'
}))

const AttachmentDiv = styled(Box)(({ theme }) => ({
  display: 'inline-grid',
  gridTemplateColumns: ' 1fr 1fr',
  flexDirection: 'row',
  width: '100%',
  minHeight: '170',
  padding: '15px 0px',
  borderBottom: `1px solid ${theme.palette.action.disabled}`,
  marginBottom: '30px'
}))

const SuccessMessage = styled(Typography)(({ theme }) => ({
  fontFamily: 'Rubik',
  fontSize: '24px',
  fontWeight: 600,
  lineHeight: '40px',
  textAlign: 'left',
  color: theme.palette.info.main,
}))

const PreviewImage = styled('img')(({ theme }) => ({
  height: "150px",
  width: "100%",
  borderRadius: "13px"
}))

const PreviewVideo = styled('video')(({ theme }) => ({
  width: "100%",
  height: "150px",
  borderRadius: "13px"
}))

export const PublishDiv = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-end',
  minWidth: '91.44px',
  height: '38px',
  padding: '9.5px 12.67px',
  borderRadius: '6.33px',
  border: `0.79px solid ${theme.palette.info.dark}`,
  width: 'fit-content'
}))

const SubTitle = styled(Typography)(({ theme }) => ({
  fontFamily: 'Rubik',
  fontSize: '14px',
  fontWeight: 700,
  lineHeight: '22px',
  color: theme.palette.text.primary,
  margin: "5px 0px"
}))

const CameraDiv = styled(Box)(({ theme }) => ({
  borderRadius: "12px",
  height: "92px",
  width: "100%",
  border: `1px dashed ${theme.palette.info.dark}`,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: 'center',
  cursor: "pointer"
}))

const TitleField = styled(Input)(({ theme }) => ({
  borderRadius: "8px",
  height: "44px",
  width: "100%",
  "& .MuiInputBase-input": {
    margin: 0,
    fontFamily: 'Rubik',
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '24px',
    color: theme.palette.info.main,
  }
}))

const LinkField = styled(Input)(({ theme }) => ({
  height: "44px",
  borderRadius: "8px",
  width: "100%",
  "& .MuiInputBase-input": {
    fontFamily: 'Rubik',
    margin: 0,
    fontWeight: 400,
    lineHeight: '24px',
    fontSize: '16px',
    color: theme.palette.info.main,
  }
}))

const FileSection = styled(Box)(({ theme }) => ({
  borderRadius: "8px",
  minHeight: "44px",
  width: "100%",
  border: `1px solid ${theme.palette.info.dark}`,
  padding: "10px",
  display: 'flex',
  background: theme.palette.background.default
}))

const DocViewDiv = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "100%",
  marginRight: '10px',
  flexDirection: 'row',
  padding: '2px',
  justifyContent: 'space-between',
  border: `0.76px solid ${theme.palette.primary.main}`,
  borderRadius: '6px',
  alignItems: 'center',
  boxShadow: `0px 1.51px 6.05px 0px #00000014`,
}))

export const CompanyName = styled(Typography)(({ theme }) => ({
  fontFamily: 'Rubik',
  fontSize: '8px',
  fontWeight: 400,
  lineHeight: '12px',
  color: theme.palette.secondary.light
}))

export const CompanyNameSubHead = styled(Typography)(({ theme }) => ({
  fontFamily: 'Rubik',
  fontSize: '14px',
  fontWeight: 700,
  lineHeight: '17px',
  color: theme.palette.secondary.main
}))

export const SearchInput = styled(Input)(({ theme }) => ({
  width: "100%",
  height: "44px",
  borderRadius: "98px",
  border: `1px solid ${theme.palette.info.dark}`,
  margin: "15px 0px 15px 8px",
  background: theme.palette.background.default,
  "& .MuiInputBase-input": {
    border: "none",
    fontFamily: 'Rubik',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '24px',
    color: theme.palette.secondary.main,
    padding: "8px"
  }
}))

const MainMenu = styled(Menu)(({ theme }) => ({
  '& .MuiPaper-root': {
    width: '192px',
    borderRadius: '8px',
    boxShadow: `0px 4px 8px 0px #00000008`,
    border: `1px solid ${theme.palette.primary.main}`,
    backgroundColor: theme.palette.background.default
  },
  '& .MuiListItem-button:hover': {
    backgroundColor: theme.palette.background.paper
  }
}))

const ImageVideoSection = styled(Box)(({ theme }) => ({
  display: 'inline-grid',
  gridTemplateColumns: '1fr 1fr',
  flexDirection: 'row',
  width: '100%',
  padding: '15px 0px',
  gap: "10px"
}))

const LinkSection = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center"
}))

const EmptyBoardHeading = styled(Typography)(({ theme }) => ({
  fontSize: "30px",
  fontWeight: 400,
  lineHeight: "40px",
  color: theme.palette.info.light
}))

const EmptyBoardSubHeading = styled(Typography)(({ theme }) => ({
  fontSize: "16px",
  fontWeight: 400,
  lineHeight: "40px",
  color: theme.palette.info.light
}))

export const CompanyChecked = styled(Checkbox)(({ theme }) => ({
  borderRadius: '50px',
  marginRight: '5px',
  "& .ant-checkbox-inner": {
    border: `1.2px solid ${theme.palette.secondary.contrastText}`,
    borderRadius: "5px",
    height: '17px',
    width: '17px'
  },
  "& .ant-checkbox": {
    top: 0,
  },
  '& .ant-checkbox-checked .ant-checkbox-inner::after': {
    borderColor: '#FFF',
  },
  "& .ant-checkbox-checked .ant-checkbox-inner": {
    backgroundColor: theme.palette.info.contrastText,
    borderRadius: "7px"
  },
  "& .ant-checkbox-input:focus+.ant-checkbox-inner, .ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner": {
    borderColor: theme.palette.secondary.contrastText
  }
}))

export const SelectedGroupDiv = styled(Box)(({ theme }) => ({
  width: "100%",
  maxHeight: '147px',
  overflowY: 'scroll',
  padding: "20px",
  border: `1px solid ${theme.palette.info.dark}`,
  display: 'inline-grid',
  gridTemplateColumns: ' 1fr 1fr 1fr',
  flexDirection: 'row',
  borderRadius: "30px",
  marginLeft: "8px"
}))

export const SelectedGroupBox = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "32px",
  background: theme.palette.background.paper,
  maxWidth: "160px",
  marginBottom: "10px",
  borderRadius: "18px",
  padding: "4px 8px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center"
}))

const EmptyContainer = styled(Grid)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  height: "70vh"
}))

const DescriptionDiv = styled(Box)(({ theme }) => ({
  color: theme.palette.info.main
}))

const ViewOption = styled(Typography)(({ theme }) => ({
  fontSize: 14,
  color: theme.palette.info.light
}))

const EditOption = styled(Typography)(({ theme }) => ({
  fontSize: 14,
  color: theme.palette.info.light
}))

const CloseDiv = styled(CloseIcon)(({ theme }) => ({
  position: "relative",
  bottom: "85%",
  left: "90%",
  height: "15px",
  width: "15px",
  fill: theme.palette.secondary.main
}))
// Customizable Area End

// Customizable Area End
